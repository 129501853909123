body {
    background-color: #f6f6f6;
}
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.image {
    width: 370px;
    margin-top: 50px;
}

@media(max-width: 770px){
    .container {
        margin: 0 10%;
    }
    .image {
        width: 100%;
    }
}